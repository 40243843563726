import React from "react"
import { Helmet } from "react-helmet"
import "../fonts/css/font-awesome.min.css"
import "../css/vendor/bootstrap.min.css"
import "../css/vendor/barfiller.css"
import "../css/vendor/animate.css"
import "../css/scss/style.scss"

const IndexPage = () => {
  return (
    <>
      <div className="loading">
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tanveer Singh (Front end Developer | Team Lead)</title>
        <link rel="canonical" href="https://tanveersingh.me" />
        <script src="/js/vendor/jquery.min.js" type="text/javascript" />
        <script
          src="/js/vendor/jquery-migrate-3.0.0.min.js"
          type="text/javascript"
        />
        <script src="/js/vendor/popper.min.js" type="text/javascript" />

        <script src="/js/vendor/bootstrap.min.js" type="text/javascript" />
        <script src="/js/vendor/typed.min.js" type="text/javascript" />
        <script src="/js/vendor/jquery.barfiller.js" type="text/javascript" />
        <script src="/js/vendor/particles.js" type="text/javascript" />
        <script src="/js/vendor/jquery.stellar.js" type="text/javascript" />
        <script src="/js/vendor/classie.js" type="text/javascript" />
        <script src="/js/vendor/scrollIt.min.js" type="text/javascript" />
        <script src="/js/vendor/wow.min.js" type="text/javascript" />
        <script src="/js/main.js" type="text/javascript" />
      </Helmet>
      <div className="menu-wrap">
        <nav className="menu">
          <div className="icon-list">
            <a href="#" data-scroll-nav="0">
              <i className="fa fa-fw fa-home"></i>
              <span>Home</span>
            </a>
            <a href="#" data-scroll-nav="1">
              <i className="fa fa-fw fa-user"></i>
              <span>About Me</span>
            </a>
            <a href="#" data-scroll-nav="2">
              <i className="fa fa-fw fa-briefcase"></i>
              <span>Experience</span>
            </a>
            <a href="#" data-scroll-nav="3">
              <i className="fa fa-fw fa-tasks"></i>
              <span>Services</span>
            </a>
            <a href="#" data-scroll-nav="4">
              <i className="fa fa-fw fa-phone"></i>
              <span>Contact</span>
            </a>
          </div>
        </nav>
      </div>
      <button className="menu-button" id="open-button"></button>

      <div className="content-wrap">
        <header data-scroll-index="0">
          <div id="particles-js"></div>
          <div
            className="greet-picture text-center col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 wow fadeInDown"
            data-wow-duration="0.5s"
            data-wow-offset="10"
          >
            <img src="./img/greet-pic.png" alt="" />
            <h1 className="welcomeText">Hi, I'm Tanveer Singh</h1>
            <p id="auto-type-text">UI/UX Developer</p>
          </div>
        </header>

        {/* <!-- About me section  --> */}
        <section
          id="about-me"
          className="row section-holder"
          data-scroll-index="1"
        >
          <div className="col-md-8 offset-md-2">
            <h1
              className="mb-4 wow fadeInUp"
              data-wow-delay="0s"
              data-wow-duration="0.7s"
            >
              <span className="main-Heading">About me</span>
              <span className="sub-Heading">Welcome To My Site</span>
            </h1>
            <article className="text-center">
              <p
                className="wow fadeInRight col"
                data-wow-delay="0s"
                data-wow-duration="0.7s"
              >
                I am a Sr. Front-end Developer / Team Lead who can design, too,
                with a strong focus on UX experience. I love to get new
                experiences and always learn from my surroundings. I’ve done
                many projects, including enterprise-level projects for fortune
                500 companies. I am always ready to take on new challenges. I am
                looking forward to new challenges or making new connections.
              </p>
              <div className="buttons-container ">
                <a
                  href="tanveer-singh-cv.pdf"
                  className="btn btn-primary wow fadeInRight"
                  download="tanveer-singh-cv.pdf"
                  rel="noopener"
                  target="_blank"
                  data-wow-delay="0s"
                  data-wow-duration="0.7s"
                >
                  Download CV
                </a>
              </div>
            </article>
          </div>
        </section>

        {/* <!--  Skills and experience sections here --> */}
        <div className="container-fluid" data-scroll-index="2">
          <section id="experience-skills" className="row section-holder pb-5">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md">
                  <h1
                    className="text-left mb-5 wow fadeInUp"
                    data-wow-delay="0s"
                    data-wow-duration="0.5s"
                  >
                    <span className="main-Heading">My Skills</span>
                    <span className="sub-Heading">Technologies I Use</span>
                  </h1>
                  <article
                    className="wow fadeInLeft"
                    data-wow-delay="0s"
                    data-wow-duration="0.5s"
                  >
                    <div className="progress-bars-container">
                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">
                          UI Designing
                        </div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar8" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="90"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">
                          HTML5/CSS3
                        </div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar1" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="95"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">
                          Javascript / ES
                        </div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar2" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="85"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">React</div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar3" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="88"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">Redux</div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar4" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="88"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">NextJS</div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar5" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="70"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">Angular</div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar9" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="70"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">Cordova</div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar6" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="70"></span>
                          </div>
                        </div>
                      </div>

                      <div className="progress-bar-holder row">
                        <div className="col-3 progress-bar-title">
                          Photoshop
                        </div>
                        <div className="col-9 col-sm-8 pt-1">
                          <div id="bar7" className="barfiller">
                            <span className="tip"></span>
                            <span className="fill" data-percentage="80"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                <div className="col-md ">
                  <h1
                    className="text-left mb-5 wow fadeInUp"
                    data-wow-delay="0s"
                    data-wow-duration="0.5s"
                  >
                    <span className="main-Heading">Experience</span>
                    <span className="sub-Heading">Companies I Worked</span>
                  </h1>
                  <article
                    className="row wow fadeInRight"
                    data-wow-delay="0s"
                    data-wow-duration="0.5s"
                  >
                    <div className="col-12 experience-list">
                      <div className="experience-item">
                        <div className="experience-duration-design">
                          July 2020 - Present
                          <span className="experience-designation">
                            {" "}
                            - Senior Front-end Developer / Team Lead
                          </span>
                        </div>
                        <div className="experience-company">
                          Thinkific Labs Inc. (Canada)
                        </div>
                      </div>

                      <div className="experience-item">
                        <div className="experience-duration-design">
                          Sept 2018 - July 2020
                          <span className="experience-designation">
                            {" "}
                            - Senior Associate (Front-end Developer)
                          </span>
                        </div>
                        <div className="experience-company">
                          DBS Bank (Singapore)
                        </div>
                      </div>

                      <div className="experience-item">
                        <div className="experience-duration-design">
                          May 2015 - Aug 2018
                          <span className="experience-designation">
                            {" "}
                            - Senior Software Engineer
                          </span>
                        </div>
                        <div className="experience-company">
                          Xchanging Solutions (Singapore) Limited
                        </div>
                      </div>

                      <div className="experience-item">
                        <div className="experience-duration-design">
                          July 2014 - April 2015
                          <span className="experience-designation">
                            {" "}
                            - Senior Software Engineer
                          </span>
                        </div>
                        <div className="experience-company">Tech Mahindra</div>
                      </div>

                      <div className="experience-item">
                        <div className="experience-duration-design">
                          August 2012 - April 2014
                          <span className="experience-designation">
                            {" "}
                            - Senior Web Designer
                          </span>
                        </div>
                        <div className="experience-company">
                          Kochar Infotech Private Limited
                        </div>
                      </div>

                      <div className="experience-item">
                        <div className="experience-duration-design">
                          January 2010 - August 2012
                          <span className="experience-designation">
                            {" "}
                            - Web Designer
                          </span>
                        </div>
                        <div className="experience-company">Peer Infotech</div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <!-- Service section starts from here --> */}
        <div className="container-fluid" data-scroll-index="3">
          <section
            id="services"
            className="row section-holder"
            data-stellar-background-ratio="1"
          >
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  {/* <!-- Heading for Services Section --> */}
                  <h1 className="text-center mb-5 lighter">
                    <span className="main-Heading">Services</span>
                    <span className="sub-Heading ">What I Do</span>
                  </h1>
                  {/* <!-- Services cards --> */}
                  <div
                    className="row wow fadeInRight"
                    data-wow-delay="0s"
                    data-wow-duration="0.5s"
                  >
                    <div className="col-md-4 services-card">
                      <div className="service-icon">
                        <i
                          className="fa fa-paint-brush fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="service-title">Web Design</div>
                      <div className="service-description">
                        Design is the crucial part of any website. A unique
                        design promote the website more. However, usability
                        comes first, a design which is not usable by your
                        audience is like having no design.{" "}
                      </div>
                    </div>

                    <div className="col-md-4 services-card">
                      <div className="service-icon">
                        <i className="fa fa-code fa-3x" aria-hidden="true"></i>
                      </div>
                      <div className="service-title">Front End Development</div>
                      <div className="service-description">
                        If you already have designs, no worries I can still help
                        you by doing front end development even mean stack as
                        well. Just pass me the design images and relax. I will
                        handle everything.{" "}
                      </div>
                    </div>

                    <div className="col-md-4 services-card">
                      <div className="service-icon">
                        <i
                          className="fa fa-mobile fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="service-title">Hybrid Mobile Apps</div>
                      <div className="service-description">
                        Nowdays, mobile applications are must for every company.
                        I can provide you with a solution which will be super
                        fast as native application while cutting development
                        time in half.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <!-- Contact me section  --> */}
        <section
          id="contact-me"
          className="row section-holder"
          data-scroll-index="4"
        >
          <div className="col-md-8 offset-md-2">
            <h1 className="mb-4">
              <span className="main-Heading">Contact me</span>
              <span className="sub-Heading">Get in Touch</span>
            </h1>
            <article className="row pt-4">
              <div
                className="col-sm-4 contact-details wow fadeInLeft"
                data-wow-delay="0s"
                data-wow-duration="0.7s"
              >
                <div className="col-12 clearfix">
                  <div className="float-left">
                    <i className="fa fa-home"></i>
                  </div>
                  <div className="float-left pl-2">
                    #95-19525 73 Ave, Surrey,
                    <br />
                    BC, Canada.
                    <br />
                    V4N 6L7
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-12 clearfix">
                  <div className="float-left">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div className="float-left pl-2">
                    <a href="mailto:tanveer.singh926@gmail.com">
                      tanveer.singh926@gmail.com
                    </a>
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-12 clearfix">
                  <div className="float-left">
                    <i className="fa fa-phone-square"></i>
                  </div>
                  <div className="float-left pl-2">(236) 995-7177</div>
                </div>
              </div>
              <div
                className="col-sm-8 wow fadeInRight"
                data-wow-delay="0s"
                data-wow-duration="0.7s"
              >
                <form name="contactForm">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="name"
                          placeholder="Enter Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          required
                          id="email"
                          placeholder="Enter Email ID"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          required
                          id="phone"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 form-group">
                      <textarea
                        cols="30"
                        rows="7"
                        className="form-control"
                        id="message"
                        required
                        placeholder="Enter Comments"
                      ></textarea>
                    </div>
                    <div className="text-right col">
                      <button
                        className="btn btn-primary"
                        type="button"
                        id="sendMessage"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </article>
          </div>
        </section>

        {/* <!-- Footer section --> */}
        <footer className="container-fluid ">
          <div className="row section-holder">
            <div
              className="col-12 text-center footer-icons wow fadeInUp"
              data-wow-delay="0s"
              data-wow-duration="0.8s"
            >
              <a
                rel="noopener"
                target="_blank"
                href="https://www.facebook.com/Mr.Tanveer.singh"
              >
                <i className="fa fa-2x fa-facebook"></i>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="https://www.linkedin.com/in/tanveer-singh-4b738933/"
              >
                <i className="fa fa-2x fa-linkedin"></i>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="skype:tanveer.singh926?userinfo"
              >
                <i className="fa fa-2x fa-skype"></i>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="https://twitter.com/tanveersingh926?lang=en"
              >
                <i className="fa fa-2x fa-twitter"></i>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="https://plus.google.com/105103455325273784756"
              >
                <i className="fa fa-2x fa-google-plus"></i>
              </a>
            </div>
            <div
              className="col-12 text-center pt-5 copyright-text  wow fadeInleft"
              data-wow-delay="0s"
              data-wow-duration="0.8s"
            >
              © Copyright 2020 - Tanveer Singh Taneja
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default IndexPage
